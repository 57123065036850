<template>
  <div>
    <app-card>
      <template #body>
        <task-form @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="goToTicket">
        {{ $t('btn.back_to_ticket') }}
      </b-button>
      <b-button variant="success" @click="create">
        {{ $t('btn.create') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import TaskForm from './components/TaskForm';

export default {
  components: {
    AppCard,
    TaskForm
  },
  data: () => ({
    form: {},
    ticketId: null
  }),
  beforeMount() {
    this.ticketId = this.$route.params.ticketId;
  },
  methods: {
    goToTicket() {
      this.ticketId = this.$route.params.ticketId;
      this.$router.push({name: 'showTicket', params: {id: this.ticketId}})
    },
    create() {
      this.$store.dispatch('tasksStore/CREATE', this.form).then(() => {
        this.$router.push({name: 'showTicket', params: {id: this.ticketId}});
      });
    }
  }
}
</script>