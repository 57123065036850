<template>
  <div class="form-group">
    <div v-if="label" class="form-group__label-wrapper" :class="{ required }">
      <label class="form-group__label">{{ label }}</label>
    </div>
    <div v-show="!images.length">
      <input ref="imagesWrapper" type="file" multiple hidden @change="onChange">
      <div
          class="d-flex flex-column justify-content-center align-items-center border border-secondary rounded cursor-pointer p-5"
          @click="chooseFiles"
      >
        <span class="svg-icon svg-icon-secondary svg-icon-3x">
          <inline-svg src="/media/svg/icons/Files/Pictures1.svg"/>
        </span>
        <div class="text-dark-50 mt-3">{{ $t('placeholder.choose_images') }}</div>
      </div>
      <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
      <p v-if="withError" class="error-message text-danger">{{ errorMessage }}</p>
    </div>
    <div v-show="images.length">
      <app-images-preview :entity="entity" :entity-id="entityId" :images="images" @uploadNew="chooseFiles" @delete="getData"/>
    </div>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';
import AppImagesPreview from './AppImagesPreview';
import {mapGetters} from 'vuex';

export default {
  components: {
    AppImagesPreview
  },
  props: {
    entity: {
      type: String,
      default: ''
    },
    entityId: {
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    targetImages: []
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      images: 'imagesStore/IMAGES'
    }),
    computedFormData() {
      let formData = new FormData();

      this.targetImages.forEach(image => {
        formData.append('images[]', image);
      })

      return formData;
    }
  },
  watch: {
    computedFormData(data) {
      if (data) {
        this.upload(data);
      }
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('imagesStore/GET', {entity: this.entity, entityId: this.entityId});
    },
    upload(data) {
      this.$store.dispatch('imagesStore/UPLOAD', {
        entity: this.entity,
        entityId: this.entityId,
        data: data
      }).then(() => {
        this.getData();
      })
    },
    onChange(e) {
      this.targetImages = e.target.files;
    },
    chooseFiles() {
      this.$refs.imagesWrapper.click();
    }
  }
}
</script>