<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.title')"
            v-model="form.title"
            :placeholder="$t('placeholder.enter_title')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-textarea
            :label="$t('label.description')"
            v-model="form.description"
            :placeholder="$t('placeholder.enter_description')"
            rows="5"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.company')"
            :placeholder="$t('placeholder.select_company')"
            :value="companiesList.find(el => el.id === form.company_id)"
            :options="companiesList"
            @select="form.company_id = $event.id"
            @remove="form.company_id = null"
        />
      </b-col>
    </b-row>
    <b-row v-if="updatePageFlag">
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.status')"
            :placeholder="$t('placeholder.select_status')"
            :value="statusesList.find(el => el.id === form.status_id)"
            :options="statusesList"
            required
            @select="form.status_id = $event.id"
            @remove="form.status_id = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.responsible')"
            :placeholder="$t('placeholder.select_responsible')"
            :value="usersList.find(el => el.id === form.responsible_id)"
            :options="usersList"
            required
            @select="form.responsible_id = $event.id"
            @remove="form.responsible_id = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-datepicker
            :label="$t('label.deadline')"
            :placeholder="$t('placeholder.choose_date')"
            :value="form.deadline"
            :min-date="new Date()"
            required
            @change="form.deadline = $event"
        />
      </b-col>
    </b-row>
    <b-row v-if="task">
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-images-upload
            :label="$t('label.images')"
            entity="task"
            :entity-id="task.id"
            :images="form.images"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';
import AppTextarea from '@/components/form-groups/AppTextarea';
import AppImagesUpload from '@/components/images/AppImagesUpload';

export default {
  components: {
    AppDatepicker,
    AppInput,
    AppMultiselect,
    AppTextarea,
    AppImagesUpload
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    updatePageFlag: false,
    form: {
      company_id: null,
      deadline: null,
      description: '',
      responsible_id: null,
      status_id: null,
      title: '',
      ticket_id: null
    }
  }),
  beforeMount() {
    this.form.ticket_id = this.$route.params.ticketId;
    this.$store.dispatch('companiesStore/GET_COMPANIES');
    this.$store.dispatch('ticketsStore/GET_TICKET', this.form.ticket_id);
    this.$store.dispatch('tasksStore/GET_STATUSES');
  },
  computed: {
    ...mapGetters({
      companiesList: 'companiesStore/COMPANIES',
      ticket: 'ticketsStore/TICKET',
      statusesList: 'tasksStore/STATUSES',
      usersList: 'usersStore/USERS'
    })
  },
  watch: {
    ticket(data) {
      if (data) {
        if (!this.updatePageFlag) {
          this.form.title = data.title;
          this.form.description = data.description;
        }
        this.$store.dispatch('usersStore/GET_USERS', {block_id: data.location.block.id});
      }
    },
    task(data) {
      this.updatePageFlag = true;

      this.form.company_id = data.company?.id;
      this.form.deadline = new Date(data.deadline.replace(/(\d{2})\.(\d{2})\.(\d{4})/,'$3-$2-$1'));
      this.form.description = data.description;
      this.form.responsible_id = data.responsible.id;
      this.form.status_id = data.status.id;
      this.form.title = data.title;
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>