<template>
  <div class="d-flex align-items-center overflow-auto">
    <div v-for="image in images" class="symbol symbol-75 position-relative mr-3">
      <span class="svg-icon svg-icon-danger position-absolute right-0 cursor-pointer" @click="beforeDelete(image.id)">
        <inline-svg src="/media/svg/icons/Code/Error-circle.svg"></inline-svg>
      </span>
      <img alt="Pic" :src="image.path"/>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <span class="svg-icon svg-icon-primary svg-icon-3x rotate-45 cursor-pointer" @click="$emit('uploadNew')">
        <inline-svg src="/media/svg/icons/Code/Error-circle.svg"></inline-svg>
      </span>
    </div>

    <app-modal
        id="delete-image-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_image') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import AppModal from '@/components/modals/AppModal';

export default {
  components: {
    AppModal
  },
  props: {
    entity: {
      default: ''
    },
    entityId: {
      default: null
    },
    images: {
      default: () => []
    }
  },
  data: () => ({
    itemToDelete: null
  }),
  methods: {
    beforeDelete(id) {
      this.itemToDelete = id;
      this.$bvModal.show('delete-image-modal');
    },
    deleteItem() {
      this.$store.dispatch('imagesStore/DELETE', {
        entity: this.entity,
        entityId: this.entityId,
        id: this.itemToDelete
      }).then(() => {
        this.itemToDelete = null;
        this.$emit('delete');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate-45 {
  transform: rotate(45deg);
}
</style>